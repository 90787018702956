@font-face {
  font-family: 'Source SerifPro Regular';
  src: local('Source SerifPro Regular'), local('SourceSerifPro-Regular'),
    url('#{$fonts-path}Source_Serif_Pro/SourceSerifPro-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans Regular'), local('Noto-Sans-Regular'),
    url('#{$fonts-path}Noto_Sans/NotoSans-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
